import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IbaseService } from 'src/app/@shared/base-service/ibase-service';
import { MessageService } from 'src/app/@shared/message/message.service';
import { api } from 'src/app/@shared/services/api';
import { CommonService } from 'src/app/@shared/services/common.service';
import { WebserviceService } from 'src/app/@shared/services/webservice.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  public api = api;
  public env = environment;
  formData!: FormGroup;
  submitted = false;
  errors: any;
  oldPasswordTextType= false;
  newPasswordTextType= false;
  confirmPasswordTextType= false;
  @Input() isModal!: any;
  @Output() openLogin = new EventEmitter();

  constructor(public service: WebserviceService,
    public _common: CommonService,
    public _message: MessageService,
    public router: Router,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.formData = this.fb.group({
      old_password: ['', Validators.required],
      new_password: ['', [Validators.required, Validators.minLength(6)]],
      new_password_re: ['', Validators.required],
    }, {
      validator: this._common.mustMatch('new_password', 'new_password_re'),
    });
  }

  getServices(): IbaseService<any> {
    return this.service;
  }

  get f() {
    return this.formData.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.formData.invalid) {
      return;
    }

    let req = this._common.JSON_to_URLEncoded(this.formData.value);
    this.getServices().Post(`${this.env.baseUrl + this.api.changePass}`, req).subscribe(
      (res) => {
        if (res.status == true) {
          this._message.succmessage(res.data.message);
          this._common.removeLocalstorage();
          // this.formData.reset();
          // this.router.navigate(['/login']);
          this.router.navigate(['/home']);
          // window.location.reload();
          //this.openLogin.emit('')
        } else {
          this.errors = res.data;
          this._message.errmessage(res.error.message);
        }
      },
      (err) => {
        console.log(err);
      });
  }

  toggleOldPasswordFieldTextType() {
    this.oldPasswordTextType = !this.oldPasswordTextType;
  }

  toggleNewPasswordFieldTextType() {
    this.newPasswordTextType = !this.newPasswordTextType;
  }

  toggleConfirmPasswordFieldTextType() {
    this.confirmPasswordTextType = !this.confirmPasswordTextType;
  }

}
